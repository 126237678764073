import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth, GoogleAuthProvider  } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'; // Import Firestore
import { getAnalytics } from 'firebase/analytics'; // Import Analytics

const firebaseConfig = {
    apiKey: "AIzaSyAR5t7zeFwC-x5CXL7yMbg7tzVjjrRvhcA",
    authDomain: "amazing-sportstats-admin.firebaseapp.com",
    projectId: "amazing-sportstats-admin",
    storageBucket: "amazing-sportstats-admin.firebasestorage.app",
    messagingSenderId: "990114707020",
    appId: "1:990114707020:web:0c18bf0207f9c2fce066e9",
    measurementId: "G-GYFCQMZLBJ"
};

// Ensure Firebase is only initialized once
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

// Initialize Google Analytics (optional chaining if not in all environments)
const analytics = getAnalytics(app);

// Initialize Firebase Auth
const auth = getAuth(app);
  auth.useDeviceLanguage();

// Initialize Google Auth Provider
const googleProvider = new GoogleAuthProvider();

// Initialize Firestore
const db = getFirestore(app); // Get a reference to Firestore

export { db, auth, googleProvider, analytics }; // Export Firestore and Auth for use in your app