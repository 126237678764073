import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';
import { auth } from '../utils/firebase'; // Import the auth object

import LoadingComponent from './loading';

interface PrivateRouteProps {
    path: string;
  }

  const PrivateRoute: React.FC<PrivateRouteProps> = ({  children }) => {
    const { loading } = useAuth();
    const location = useLocation();

    if (loading) {
      return <LoadingComponent/>;
    }
    
    return auth.currentUser ? (
      <> {children} </>
    ) : (
      <Navigate to="/login" state={{ from: location }} />
    );
  };
export default PrivateRoute;
